import { TFunction } from 'i18next';
import * as yup from 'yup';
// 1-4 cases for latin letters https://unicode-table.com/en/blocks/
// !?,./0-9 Basic symbols etc.
// a-z Basic Latin
// \u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff Latin-1 Supplement
// \u0100-\u017F Latin Extended-A
// \u0180-\u024F Latin Extended-B
const basicSymbols = '\u0020-\u003F';
const latinBasicRegLine = 'a-z';
const latinSupplementRegLine = '\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff';
const latinExtendedARegLine = '\u0100-\u017F';
const latinExtendedBRegLine = '\u0180-\u024F';
const latinUnderScore = '\u005F';
const latinAndBasicSymbolsRegLine = [
  basicSymbols,
  latinBasicRegLine,
  latinSupplementRegLine,
  latinExtendedARegLine,
  latinExtendedBRegLine,
].join('');

const latinAndBasicSymbolsRegLineForEmail = [
  latinAndBasicSymbolsRegLine,
  latinUnderScore,
].join('');
export type SchemaFunction<T = yup.StringSchema> = (
  t: TFunction,
  schema: T
) => T;

export const schemaField = <T = yup.StringSchema>(
  t: TFunction,
  schema: T,
  ...funcs: Array<SchemaFunction<T>>
): T => {
  const [head, ...tail] = funcs;
  if (!head) {
    return schema;
  }
  if (tail.length === 0) {
    return head(t, schema);
  }
  return schemaField(t, head(t, schema), ...tail);
};

export const bindTFuncToSchemaField = (t: TFunction) => {
  return function schema<T = yup.StringSchema>(
    schema: T,
    ...funcs: Array<SchemaFunction<T>>
  ) {
    return schemaField<T>(t, schema, ...funcs);
  };
};

const requiredMessage = (t: TFunction) =>
  t('form.field.error_required', 'Required');

export const emailExistMessage = (t: TFunction) =>
  t(
    'dis-validation-error.email-already-exists',
    'The email already exists in the system.'
  );

export const isRequired: SchemaFunction<yup.StringSchema> = (t, schema) =>
  schema.required(requiredMessage(t));

export const isChecked: SchemaFunction<yup.BooleanSchema> = (t, schema) =>
  schema.required().oneOf([true], requiredMessage(t));

export const isEmail: SchemaFunction<yup.StringSchema> = (t, schema) =>
  schema.email(t('form.field.error_invalid_email', 'Invalid email address'));

export const isNumber: SchemaFunction<yup.StringSchema> = (t, schema) =>
  schema
    .matches(
      /^[0-9,().\-/ ]+$/,
      t(
        'edit-profile.phone.form.number.error_can_only_contain',
        'Field can only contain numbers, space " ", dash "-", slash "/", comma ",", parentheses "()" or dot "."'
      )
    )
    .matches(
      /^[(0-9]/,
      t(
        'edit-profile.phone.form.number.error_can_only_contain',
        'Field must start with either a number or open parentheses "("'
      )
    );

export const isNotRotaryEmail: SchemaFunction<yup.StringSchema> = (t, schema) =>
  schema.matches(
    /^((?!@rotary.org).)*$/i, // We are limiting this to only block @rotary.org strictly to block a security loophole, this may need to be updated at a later date
    t(
      'form.field.email.must_not_include_rotary_email',
      'Can not use an email containing rotary.org'
    )
  );

export const isURL: SchemaFunction<yup.StringSchema> = (t, schema) =>
  schema.matches(
    /^(https?:\/\/)?[\w.-]+(?:\.[a-zA-Z]{2,})+$/,
    t('form.field.error_invalid_url', 'Invalid URL')
  );

export const matchLatinChars: SchemaFunction<yup.StringSchema> = (
  t,
  schema
) => {
  const regexp = new RegExp(`^([${latinAndBasicSymbolsRegLine}]{0,}]*)$`, 'gi');
  return schema.matches(
    regexp,
    t('form.field.error_non_latin', 'Field cannot contain non-latin characters')
  );
};

export const matchLatinCharsAndIgnoreSign: SchemaFunction<yup.StringSchema> = (
  t,
  schema
) => {
  const regexp = new RegExp(
    `^([${latinAndBasicSymbolsRegLineForEmail}^@]{0,}]*)$`,
    'gi'
  );
  return schema.matches(
    regexp,
    t('form.field.error_non_latin', 'Field cannot contain non-latin characters')
  );
};
export const matchValidStartCharacter: SchemaFunction<yup.StringSchema> = (
  t,
  schema
) =>
  schema.matches(
    /^([^.\-\s])/,
    t(
      'form.field.error_invalid_start',
      'Field cannot start with a ".", " " or "-"'
    )
  );

export const matchValidStartCharacterExtended: SchemaFunction<yup.StringSchema> = (
  t,
  schema
) =>
  schema.matches(
    /^([^.,\-\s])/,
    t(
      'form.field.error_invalid_start_2',
      'Field cannot start with a ".", ",", " " or "-"'
    )
  );

export const hasMinLength: (
  number: number
) => SchemaFunction<yup.StringSchema> = number => (t, schema) =>
  schema.min(
    number,
    t(
      'form.field.error_too_short',
      'Field requires minimum {{number}} characters',
      { number }
    )
  );

export const hasMaxLength: (
  number: number
) => SchemaFunction<yup.StringSchema> = number => (t, schema) =>
  schema.max(
    number,
    t(
      'form.field.error_too_long',
      'Field cannot exceed {{number}} characters',
      { number }
    )
  );

export const hasValidPhoneNumberLength: SchemaFunction<yup.StringSchema> = (
  t,
  schema
) =>
  schema
    .min(
      7,
      t(
        'form.field.error_phonenumber_min_digits',
        'Phone number must be between 7 - 30 digits long'
      )
    )
    .max(
      30,
      t(
        'form.field.error_phonenumber_max_digits',
        'Phone number cannot exceed 30 digits'
      )
    );

export const hasNoConsecutiveDots: SchemaFunction<yup.StringSchema> = (
  t,
  schema
) =>
  schema.matches(
    /^((?!\.\.).)*$/,
    t(
      'form.field.error_cannot_contain_multiple_dots',
      'Field cannot contain two or more consecutive "."'
    )
  );

export const doesNotStartOrEndWithDot: SchemaFunction<yup.StringSchema> = (
  t,
  schema
) =>
  schema.matches(
    /^[^.].*[^.]$/,
    t(
      'form.field.error_cannot_start_or_end_with_dot',
      'Field cannot start or end with a "."'
    )
  );

export const doesNotHaveNumbers: SchemaFunction<yup.StringSchema> = (
  t,
  schema
) =>
  schema.matches(
    /^\D+$/,
    t('form.field.error_contains_number', 'Field cannot contain numbers')
  );

const requiredPANErrMessage = (t: TFunction) =>
  t(
    'form.field.pan_error_required',
    'Please enter a valid Permanent Account Number (PAN)'
  );

const requiredConfirmPANErrMessage = (t: TFunction) =>
  t(
    'form.field.confirm_pan_error_required',
    'Please enter a matching Permanent Account Number (PAN)'
  );

export const isValidIndiaPanNumber: SchemaFunction<yup.StringSchema> = (
  t,
  schema
) =>
  schema
    .matches(
      /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i,
      t(
        'form.field.india-pan.valid_pan_number',
        'Please enter a valid Permanent Account Number (PAN)'
      )
    )
    .required(requiredPANErrMessage(t));

export const isRequiredConfirmPAN: SchemaFunction<yup.StringSchema> = (
  t,
  schema
) => schema.required(requiredConfirmPANErrMessage(t));

const requiredConfirmRRNErrMessage = (t: TFunction) =>
  t(
    'form.field.confirm_rrn_error_required',
    'Please enter a matching Resident Registration Number (RRN)'
  );

const requiredRRNErrMessage = (t: TFunction) =>
  t(
    'form.field.rrn_error_required',
    'Please enter a valid Resident Registration Number (RRN)'
  );

const requiredCheckboxErrMessage = (t: TFunction) =>
  t('form.field.checkbox_error_required', 'Confirmation needed.');

const requiredFCErrMessage = (t: TFunction) =>
  t('form.field.italy_fc_error_required', 'Please enter a valid Fiscal Code');

const requiredConfirmFCMessage = (t: TFunction) =>
  t(
    'form.field.confirm_italy_fc_error_required',
    'Please enter a matching Fiscal Code'
  );

export const isValidKoreanRrnNumber: SchemaFunction<yup.StringSchema> = (
  t,
  schema
) =>
  schema
    .matches(
      /^[0-9]{13}$/i,
      t(
        'form.field.korean-rrn.valid_rrn_number',
        'Please enter a valid Resident Registration Number (RRN)'
      )
    )
    .required(requiredRRNErrMessage(t));

export const isRequiredConfirmRRN: SchemaFunction<yup.StringSchema> = (
  t,
  schema
) => schema.required(requiredConfirmRRNErrMessage(t));

export const isRequiredConfirmCheckbox: SchemaFunction<yup.BooleanSchema> = (
  t,
  schema
) => schema.required(requiredCheckboxErrMessage(t));

export const isRequiredConfirmFC: SchemaFunction<yup.StringSchema> = (
  t,
  schema
) => schema.required(requiredConfirmFCMessage(t));

export const isValidItalyFiscalCode: SchemaFunction<yup.StringSchema> = (
  t,
  schema
) =>
  schema
    .matches(
      /^[A-Z]{3}[A-Z]{3}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i,
      t('form.field.italy-fc.valid_fc', 'Please enter a valid Fiscal Code')
    )
    .required(requiredFCErrMessage(t));
